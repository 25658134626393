<template>
  <div>
    <h4>Муддатли тўлов маълумотлари</h4>
    <b-overlay :show="form_loading">
      <b-form class="auth-login-form mt-2">
        <b-row>
          <b-col md="4">
            <b-form-group
              :label="$t('Срок рассрочки (месяц)')"
              label-for="credit_months"
            >
              <b-form-input
                id="credit_months"
                v-model="form.credit_months"
                v-mask="'##'"
                name="credit_months"
                debounce="500"
                placeholder="12"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              :label="$t('Cумма предоплаты')"
              label-for="in_advance_payment_amount"
            >
              <cleave
                id="in_advance_payment_amount"
                v-model="form.in_advance_payment_amount"
                class="form-control"
                :options="cleaveOption"
                placeholder="0"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              :label="$t('Дата оплаты')"
              label-for="credit_payment_day"
            >
              <b-form-select
                id="from_warehouse_id"
                v-model="form.credit_payment_day"
                :placeholder="$t('Дата оплаты')"
                :options="endDays"
                @change="changeEndDay(form.credit_payment_day)"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label-for="credit_amount">
              <span slot="label" class="font-weight-bolder">{{
                $t("Сумма рассрочки")
              }}</span>
              <h2 class="font-weight-bolder text-success">
                {{ form.credit_amount | formatPrice }} so'm
              </h2>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="$t('Дата начала')" label-for="start_date">
              <h2>{{ today("dmy") }}</h2>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              v-if="form.end_date"
              :label="$t('Дата окончания')"
              label-for="end_date"
            >
              <!-- {{ formatDateYmd(form.end_date) }} -->
              <h2>{{ filterDate(form.end_date) }}</h2>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import { required } from "@validations";
import { mapActions } from "vuex";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

export default {
  name: "Create",
  components: {
    Cleave,
  },
  props: {
    product: {
      type: Object,
      default: () => null,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      endDays: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: "thousand" },
      loading: false,
      form_loading: false,
      required,
    };
  },
  computed: {
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`;
    },
  },
  watch: {
    "form.credit_months": function (val) {
      if (val) {
        this.getCreditAmount();
      }
    },
    "form.in_advance_payment_amount": function (val, oldVal) {
      this.getCreditAmount();
    },
  },
  mounted() {
    if (this.product) {
      this.form.products = [];
      this.form.products.push(this.product);
      this.form.products[0].quantity = 1;
    }
    this.fetchSettings().then((res) => {
      this.form.credit_payment_day = res.data.data.filter(
        (c) => c.code == "credit_payment_day"
      )[0].value;
    });
    this.form.start_date = this.today("ymd");
    this.getCreditAmount = this.debounce(this.getAmount, 600);
  },
  methods: {
    debounce(fn, wait) {
      let timer;
      return function (...args) {
        if (timer) {
          clearTimeout(timer); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer = setTimeout(() => {
          fn.apply(context, args); // call the function if time expires
        }, wait);
      };
    },
    getAmount() {
      this.form_loading = true;
      this.calculateCreditAmount(this.form)
        .then((res) => {
          this.form.credit_amount = res.data;
          this.$emit("calculated", true);
        })
        .finally(() => {
          this.form_loading = false;
        });
      // if (!this.form.end_date) {
      this.form.end_date = this.calcEndDate().split(".").reverse().join("-");
      // }
    },
    today(type = "ymd") {
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = today.getFullYear();
      if (type == "ymd") {
        today = `${yyyy}-${mm}-${dd}`;
      } else {
        today = `${dd}.${mm}.${yyyy}`;
      }
      return today;
    },
    filterDate(date) {
      const type = "dmy";
      let today = new Date(date);
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = today.getFullYear();
      if (type == "ymd") {
        today = `${yyyy}-${mm}-${dd}`;
      } else {
        today = `${dd}.${mm}.${yyyy}`;
      }
      return today;
    },
    getCreditAmount() {},
    changeEndDay(day) {
      const d = new Date();
      d.setMonth(d.getMonth() + Number(this.form.credit_months));
      d.setDate(day);
      const eDate = d.toLocaleDateString();
      if (eDate.search("/") > 0) {
        this.form.end_date = `${eDate.split("/")[2]}-${eDate
          .split("/")[0]
          .padStart(2, "0")}-${eDate.split("/")[1]}`;
      } else {
        this.form.end_date = eDate.split(".").reverse().join("-");
      }
    },
    calcEndDate() {
      const d = new Date();
      d.setMonth(d.getMonth() + Number(this.form.credit_months));
      d.setDate(15);
      return d.toLocaleDateString();
    },
    ...mapActions({
      calculateCreditAmount: "resource/calculateCreditAmount",
      fetchSettings: "setting/index",
    }),
  },
};
</script>

<style scoped>
</style>
