<template>
  <b-modal
    id="modal-lg"
    v-model="visible"
    cancel-variant="outline-secondary"
    hide-footer
    no-close-on-backdrop
    centered
    @close="close"
  >
    <div class="hello">
      <StreamBarcodeReader
        v-if="userMediaSupported"
        @decode="(a, b, c) => onDecode(a, b, c)"
        @loaded="() => onLoaded()"
        @init="onInit"
      />
      <div
        v-else
        class="card text-center"
      >
        <b-badge
          pill
          variant="light-warning"
          class="f22"
        >
          Kameraga ruxsat berilmagan
        </b-badge>
      </div>
      <!-- Input Value: {{ userMediaSupported }} -->
      {{ text }}

    </div>
  </b-modal>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader'

export default {
  components: { StreamBarcodeReader },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: '',
      userMediaSupported: false,
    }
  },
  mounted() {
    navigator.permissions.query({ name: 'camera' })
      .then(permissionObj => {
        if (permissionObj.state == 'denied') {
          this.isAllowed()
        } else {
          this.userMediaSupported = true
        }
      })
      .catch(error => {
        this.isAllowed()
      })
  },
  methods: {
    onInit(promise) {
      promise.catch(function () {
        this.userMediaSupported = false
      })
    },
    isAllowed() {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
            console.log('stream.getVideoTracks()1', stream.getVideoTracks())
          } else {
            console.log('stream.getVideoTracks()', stream.getVideoTracks())
          }
          this.userMediaSupported = true
        })
        .catch(error => {
          this.userMediaSupported = false
          this.$swal({
            title: 'Пожалуйста разрешите видео на вашем устройстве',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    onDecode(code) {
      this.text = code
      if (this.text && this.text.length >= 12) {
        this.$emit('barcode', this.text)
        this.$emit('close', true)
      }
    },
    onLoaded() {
      console.log('load')
    },
    close() {
      this.$emit('close', true)
      this.visible = false
    },
  },

}
</script>
