<template>
  <div class="card p-2">
    <b-table
      v-if="contract && contract.length"
      bordered
      hover
      :items="contract"
      :fields="fields"
    >
      <template #cell(number)="data">
        {{ data.index+1 }}
      </template>
      <template #cell(date)="data">
        {{ dateDotFormat(data.item.date) }}
      </template>
      <template #cell(amount)="data">
        {{ data.item.amount|formatPrice }} so'm
      </template>
    </b-table>

  </div>
</template>

<script>
export default {
  props: {
    contract: {
      type: [Object, Array],
      default: () => null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'number',
          label: '№',
        },
        {
          key: 'date',
          label: 'Дата',
        },
        {
          key: 'amount',
          label: 'Сумма',
        },
      ],
    }
  },
  computed: {
  },
}
</script>

<style>

</style>
