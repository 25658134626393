<template>
  <div class="conatiner">
    <div class="text-center card p-2">
      <h1>{{ $t("Сканер товара") }}</h1>
      <div
        class="mt-2"
        style="margin-left: 15%; margin-right: 15%"
      >
        <b-overlay
          :show="barcodeInputLoading"
          class=""
        >
          <b-row>
            <b-col
              md="6"
              class="mt-2"
            >
              <b-input-group class="mr-2">
                <b-form-input
                  id="barcode"
                  ref="barcode"
                  v-model="product_barcode"
                  name="barcode"
                  placeholder="000000000000"
                  maxlength="12"
                  @input="getProductByBarcode(product_barcode)"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="show_camera = true"
                  >
                    <feather-icon icon="CameraIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col
              md="6"
              class="mt-2"
            >
              <!--  -->
              <template v-if="!reCall">
                <v-select
                  ref="searchProduct"
                  v-model="product_search"
                  :options="filteredWProducts"
                  class="w-100"
                  label="quantity"
                  :selectable="(option) => Number(option.quantity)"
                  placeholder="Поиск по названию"
                  @search="searchProduct"
                  @input="selectedProduct"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    <span>
                      {{
                        option.models_with_parent.parent1_name_uz +
                          " / " +
                          option.models_with_parent.parent2_name_uz +
                          " / " +
                          option.models_with_parent.name_uz
                      }}
                      <b class="text-muted">({{ option.quantity }} dona)</b>
                    </span>
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    <span>
                      {{ option.models_with_parent.name_uz }}
                    </span>
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      не найдено по запросу "{{ search }}"
                    </template>
                    <template v-else>
                      Поиск по названию
                    </template>
                  </template>
                </v-select>
              </template>
            </b-col>
          </b-row>
        </b-overlay>
      </div>
      <hr v-if="product || filteredProduct">
      <div class="row">
        <div class="col-12 col-md-6 fs18">
          <div
            v-if="product"
            class="mt-3 text-left ml-5"
          >
            <p>
              Наименование:
              <b class="text-primary">{{ product.category.name_uz }} </b>
            </p>
            <p>
              Цена продажа:
              <b
                class="text-success"
              >{{
                calcCostPrice(product.category.price_dollar) | formatPrice
              }}
                сўм
              </b>
            </p>
            <p>
              Количество товара:
              <b>{{ product.how_many_in_warehouse_quantity_in_this_category }}
              </b>
            </p>
          </div>
          <div
            v-else-if="filteredProduct"
            class="mt-3 text-left ml-5"
          >
            <p>
              Наименование:
              <b class="text-primary">{{
                filteredProduct.models_with_parent.parent1_name_uz +
                  " / " +
                  filteredProduct.models_with_parent.parent2_name_uz +
                  " / " +
                  filteredProduct.models_with_parent.name_uz
              }}</b>
            </p>
            <p>
              Цена продажа:
              <b
                class="text-success"
              >{{ filteredProduct.price | formatPrice }} сўм</b>
            </p>
            <p>
              Количество товара: <b>{{ filteredProduct.quantity }}</b>
            </p>
          </div>
        </div>
        <div
          v-if="product || filteredProduct"
          class="col-12 col-md-6 hr-left"
        >
          <CalculateCredit
            v-if="product"
            :product="product"
            :form="form"
            @calculated="show_schedule = false"
          />
          <CalculateCredit
            v-else-if="filteredProduct"
            :product="filteredProduct.one_product"
            :form="form"
            @calculated="show_schedule = false"
          />
          <b-button
            v-if="form.credit_months"
            variant="primary"
            class="mt-2"
            @click="getAction()"
          >
            <feather-icon icon="TableIcon" />
            Показать график
          </b-button>
        </div>
      </div>
      <div v-if="show_schedule">
        <hr>
        <h3>График погашения</h3>

        <schedule-table :contract="payment_list" />
      </div>
    </div>
    <ScannerModal
      :visible="show_camera"
      @barcode="setBarcode"
      @close="show_camera = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ScheduleTable from '@/components/tables/testSchedule.vue'
import vSelect from 'vue-select'
import { debounce } from 'debounce'
import ScannerModal from './modals/scanner.vue'
import CalculateCredit from './calculateCredit.vue'

export default {
  name: 'Create',
  components: {
    ScannerModal,
    CalculateCredit,
    ScheduleTable,
    vSelect,
  },
  props: {},
  data() {
    return {
      form: {
        products: [],
        in_advance_payment_amount: null,
        start_date: null,
        end_date: null,
        credit_payment_day: null,
        credit_amount: null,
        credit_months: null,
      },
      show_schedule: false,
      productsInWarehouse: [],
      product_search: '',
      reCall: false,
      payment_list: null,
      show_camera: false,
      barcodeInputLoading: false,
      product_barcode: null,
      loading: false,
      product: null,
      filteredProduct: null,
      dollar_rate: 0,
    }
  },
  computed: {
    ...mapGetters({
      warehouses: 'warehouse/GET_ITEMS',
      warehouseProducts: 'productCategory/GET_WAREHOUSE_PRODUCTS',
    }),
    filteredWProducts() {
      return this.productsInWarehouse
    },
  },
  watch: {},
  mounted() {
    // this.getWarehousAction(this.getAll)
    this.getDollarRates({ current_dollar_rate: 1 }).then(res => {
      if (res.data) {
        this.dollar_rate = res.data.data[0]?.amount
      }
    })
  },
  methods: {
    getAction() {
      this.show_schedule = true
      this.calculateCreditSchedule(this.form).then(resp => {
        if (resp.success) {
          this.payment_list = resp.data
        }
      })
    },
    clearForm() {
      this.form = {
        products: [],
        in_advance_payment_amount: null,
        start_date: null,
        end_date: null,
        credit_payment_day: null,
        credit_amount: null,
        credit_months: null,
      }
    },
    calcCostPrice(calcCostPrice) {
      return Number(calcCostPrice) * this.dollar_rate
    },
    setBarcode(barcode) {
      this.product_barcode = barcode
      this.getProductByBarcode(this.product_barcode)
    },
    getProductByBarcode(barcode) {
      if (barcode && barcode.length === 12) {
        this.barcodeInputLoading = true
        this.product = null
        this.filteredProduct = null
        this.product_search = null
        this.getWarehouseProducts({ barcode })
          .then(res => {
            if (res.success) {
              this.product = res.data
              this.clearForm()
              this.payment_list = null
              this.show_schedule = false
            }
            this.product_barcode = null
            this.$refs.barcode.focus()
          })
          .catch(() => {
            this.payment_list = null
            this.show_schedule = false
          })
          .finally(() => {
            this.barcodeInputLoading = false
          })
      } else if (barcode && barcode.length > 12) {
        this.product_barcode = Number(String(barcode).slice(0, 12))
      }
    },
    selectedProduct(item) {
      this.filteredProduct = null
      this.product = null
      this.clearForm()
      setTimeout(() => {
        this.filteredProduct = item
      }, 0)
    },
    searchProduct: debounce(function (search) {
      if (search && search.length >= 3) {
        this.barcodeInputLoading = true
        this.product = null
        this.filteredProduct = null
        this.warehouseProductsSearch({
          name_uz: search,
          per_page: 999,
          appends: 'price|one_product',
          for_hired_worker: this.isHiredWorker ? 1 : 0,
        })
          .then(res => {
            this.reCall = true
            if (res.success) {
              this.productsInWarehouse = res.data.data
              this.clearForm()
              this.payment_list = null
              this.show_schedule = false
            }
            this.product_barcode = null
            setTimeout(() => {
              this.reCall = false
            }, 0)
            setTimeout(() => {
              this.$refs.searchProduct.onSearchFocus()
              this.$refs.searchProduct.$refs.search.placeholder = search
            }, 100)
          })
          .catch(() => {
            this.payment_list = null
            this.show_schedule = false
          })
          .finally(() => {
            this.barcodeInputLoading = false
          })
      }
      setTimeout(() => {
        this.$refs.searchProduct.$refs.search.value = search
      }, 1100)
    }, 500),
    ...mapActions({
      getWarehousAction: 'warehouse/index',
      getDollarRates: 'resource/getDollarRates',
      calculateCreditSchedule: 'resource/calculateCreditSchedule',
      getWarehouseProducts: 'productCategory/warehouseProductsByBarcode',
      warehouseProductsSearch: 'productCategory/warehouseProducts',
      storeItem: 'warehouse/transferProductsStore',
    }),
  },
}
</script>

<style scoped></style>
